export default [
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['શ્રી', 'કૃષ્ણ', 'પરિશ્રમ', 'શ્રી સીમંધર સ્વામી'],
    answer: ['કૃષ્ણ'],
    reason: {
      gu: '<b>કૃષ્ણ</b> સિવાયના બધા શબ્દોમાં <b>શ્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>કૃષ્ણ</b> के अलावा सभी शब्दों में <b>શ્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>શ્ર</b> in them, except <b>કૃષ્ણ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['અશ્વ', 'નિશ્ચય', 'શ્વાન', 'વિશ્વ'],
    answer: ['નિશ્ચય'],
    reason: {
      gu: '<b>નિશ્ચય</b> સિવાયના બધા શબ્દોમાં <b>શ્વ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>નિશ્ચય</b> के अलावा सभी शब्दों में <b>શ્વ</b> का उपयोग हुआ है।',
      en: 'All words have <b>શ્વ</b> in them, except <b>નિશ્ચય</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['પશ્ચિમ', 'નિશ્ચિત', 'શ્રમ', 'નિશ્ચય'],
    answer: ['શ્રમ'],
    reason: {
      gu: '<b>શ્રમ</b> સિવાયના બધા શબ્દોમાં <b>શ્ચ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>શ્રમ</b> के अलावा सभी शब्दों में <b>શ્ચ</b> का उपयोग हुआ है।',
      en: 'All words have <b>શ્ચ</b> in them, except <b>શ્રમ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['રૂબરૂ', 'શરૂઆત', 'સ્વરૂપ', 'ઋતુ'],
    answer: ['ઋતુ'],
    reason: {
      gu: '<b>ઋતુ</b> સિવાયના બધા શબ્દોમાં <b>રૂ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ઋતુ</b> के अलावा सभी शब्दों में <b>રૂ</b> का उपयोग हुआ है।',
      en: 'All words have <b>રૂ</b> in them, except <b>ઋતુ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['પુરુષ', 'રૂપ', 'ગુરુવાર', 'પૂરું'],
    answer: ['રૂપ'],
    reason: {
      gu: '<b>રૂપ</b> સિવાયના બધા શબ્દોમાં <b>રુ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>રૂપ</b> के अलावा सभी शब्दों में <b>રુ</b> का उपयोग हुआ है।',
      en: 'All words have <b>રુ</b> in them, except <b>રૂપ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ઋણાનુબંધ', 'મદદરૂપ', 'ઋત્વિક', 'ઋગ્વેદ'],
    answer: ['મદદરૂપ'],
    reason: {
      gu: '<b>મદદરૂપ</b> સિવાયના બધા શબ્દોમાં <b>ઋ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>મદદરૂપ</b> के अलावा सभी शब्दों में <b>ઋ</b> का उपयोग हुआ है।',
      en: 'All words have <b>ઋ</b> in them, except <b>મદદરૂપ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['સંસ્કૃતિ', 'પૃથ્વી', 'કૃપા', 'આકૃતિ'],
    answer: ['પૃથ્વી'],
    reason: {
      gu: '<b>પૃથ્વી</b> સિવાયના બધા શબ્દોમાં <b>કૃ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>પૃથ્વી</b> के अलावा सभी शब्दों में <b>કૃ</b> का उपयोग हुआ है।',
      en: 'All words have <b>કૃ</b> in them, except <b>પૃથ્વી</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['સૃષ્ટિ', 'તૃતીય', 'તૃપ્તિ', 'નેતૃત્વ'],
    answer: ['સૃષ્ટિ'],
    reason: {
      gu: '<b>સૃષ્ટિ</b> સિવાયના બધા શબ્દોમાં <b>તૃ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>સૃષ્ટિ</b> के अलावा सभी शब्दों में <b>તૃ</b> का उपयोग हुआ है।',
      en: 'All words have <b>તૃ</b> in them, except <b>સૃષ્ટિ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['હૃદય', 'હૃષીકેશ', 'હૃસ્ટપુસ્ટ', 'દર્દ'],
    answer: ['દર્દ'],
    reason: {
      gu: '<b>દર્દ</b> સિવાયના બધા શબ્દોમાં <b>હૃ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>દર્દ</b> के अलावा सभी शब्दों में <b>હૃ</b> का उपयोग हुआ है।',
      en: 'All words have <b>હૃ</b> in them, except <b>દર્દ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['આમ્રપાલી', 'ભ્રમર', 'નમ્ર', 'વિનમ્ર'],
    answer: ['ભ્રમર'],
    reason: {
      gu: '<b>ભ્રમર</b> સિવાયના બધા શબ્દોમાં <b>મ્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ભ્રમર</b> के अलावा सभी शब्दों में <b>મ્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>મ્ર</b> in them, except <b>ભ્રમર</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['પ્રદેશ', 'પ્રત્યેક', 'પ્રેમ', 'ક્રમ'],
    answer: ['ક્રમ'],
    reason: {
      gu: '<b>ક્રમ</b> સિવાયના બધા શબ્દોમાં <b>પ્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ક્રમ</b> के अलावा सभी शब्दों में <b>પ્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>પ્ર</b> in them, except <b>ક્રમ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['પ્રવૃત્તિ', 'ક્રાંતિ', 'ક્રમિક', 'ચક્ર'],
    answer: ['પ્રવૃત્તિ'],
    reason: {
      gu: '<b>પ્રવૃત્તિ</b> સિવાયના બધા શબ્દોમાં <b>ક્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>પ્રવૃત્તિ</b> के अलावा सभी शब्दों में <b>ક્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>ક્ર</b> in them, except <b>પ્રવૃત્તિ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ટ્રાફિક', 'તર્કશાસ્ત્ર', 'રાષ્ટ્ર', 'ટ્રેન'],
    answer: ['તર્કશાસ્ત્ર'],
    reason: {
      gu: '<b>તર્કશાસ્ત્ર</b> સિવાયના બધા શબ્દોમાં <b>ટ્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>તર્કશાસ્ત્ર</b> के अलावा सभी शब्दों में <b>ટ્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>ટ્ર</b> in them, except <b>તર્કશાસ્ત્ર</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['સંપર્ક', 'સતર્ક', 'કર્મ', 'અર્ક'],
    answer: ['કર્મ'],
    reason: {
      gu: '<b>કર્મ</b> સિવાયના બધા શબ્દોમાં <b>ર્ક</b>નો પ્રયોગ થયો છે.',
      hi: '<b>કર્મ</b> के अलावा सभी शब्दों में <b>ર્ક</b> का उपयोग हुआ है।',
      en: 'All words have <b>ર્ક</b> in them, except <b>કર્મ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ધર્મ', 'ધાર્મિક', 'ક્રમાંક', 'નિર્મમ'],
    answer: ['ક્રમાંક'],
    reason: {
      gu: '<b>ક્રમાંક</b> સિવાયના બધા શબ્દોમાં <b>ર્મ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ક્રમાંક</b> के अलावा सभी शब्दों में <b>ર્મ</b> का उपयोग हुआ है।',
      en: 'All words have <b>ર્મ</b> in them, except <b>ક્રમાંક</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ત્રિકાળ', 'ત્રાસ', 'મંત્ર', 'સત્ય'],
    answer: ['સત્ય'],
    reason: {
      gu: '<b>સત્ય</b> સિવાયના બધા શબ્દોમાં <b>ત્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>સત્ય</b> के अलावा सभी शब्दों में <b>ત્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>ત્ર</b> in them, except <b>સત્ય</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ત્યાગ', 'સાહિત્ય', 'અગ્રણી', 'અગત્ય'],
    answer: ['અગ્રણી'],
    reason: {
      gu: '<b>અગ્રણી</b> સિવાયના બધા શબ્દોમાં <b>ત્ય</b>નો પ્રયોગ થયો છે.',
      hi: '<b>અગ્રણી</b> के अलावा सभी शब्दों में <b>ત્ય</b> का उपयोग हुआ है।',
      en: 'All words have <b>ત્ય</b> in them, except <b>અગ્રણી</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['શસ્ત્ર', 'સહસ્ત્ર', 'સ્ત્રી', 'સ્વામી'],
    answer: ['સ્વામી'],
    reason: {
      gu: '<b>સ્વામી</b> સિવાયના બધા શબ્દોમાં <b>સ્ત્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>સ્વામી</b> के अलावा सभी शब्दों में <b>સ્ત્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>સ્ત્ર</b> in them, except <b>સ્વામી</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ઉલ્લેખ', 'છેલ્લે', 'મલ્લિનાથ', 'ઉત્તમ'],
    answer: ['ઉત્તમ'],
    reason: {
      gu: '<b>ઉત્તમ</b> સિવાયના બધા શબ્દોમાં <b>લ્લ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ઉત્તમ</b> के अलावा सभी शब्दों में <b>લ્લ</b> का उपयोग हुआ है।',
      en: 'All words have <b>લ્લ</b> in them, except <b>ઉત્તમ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ઉત્તર', 'મહત્તા', 'વૃત્તિ', 'સધ્ધર'],
    answer: ['સધ્ધર'],
    reason: {
      gu: '<b>સધ્ધર</b> સિવાયના બધા શબ્દોમાં <b>ત્ત</b>નો પ્રયોગ થયો છે.',
      hi: '<b>સધ્ધર</b> के अलावा सभी शब्दों में <b>ત્ત</b> का उपयोग हुआ है।',
      en: 'All words have <b>ત્ત</b> in them, except <b>સધ્ધર</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['શુદ્ધ', 'પદ્ધતિ', 'સમૃદ્ધ', 'હૃદય'],
    answer: ['હૃદય'],
    reason: {
      gu: '<b>હૃદય</b> સિવાયના બધા શબ્દોમાં <b>દ્ધ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>હૃદય</b> के अलावा सभी शब्दों में <b>દ્ધ</b> का उपयोग हुआ है।',
      en: 'All words have <b>દ્ધ</b> in them, except <b>હૃદય</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['દ્વારા', 'ચંદ્ર', 'દ્વીપ', 'દ્વેષ'],
    answer: ['ચંદ્ર'],
    reason: {
      gu: '<b>ચંદ્ર</b> સિવાયના બધા શબ્દોમાં <b>દ્વ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>ચંદ્ર</b> के अलावा सभी शब्दों में <b>દ્વ</b> का उपयोग हुआ है।',
      en: 'All words have <b>દ્વ</b> in them, except <b>ચંદ્ર</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ઉપદ્રવ', 'ચંદ્ર', 'દ્રશ્ય', 'વિદ્યા'],
    answer: ['વિદ્યા'],
    reason: {
      gu: '<b>વિદ્યા</b> સિવાયના બધા શબ્દોમાં <b>દ્ર</b>નો પ્રયોગ થયો છે.',
      hi: '<b>વિદ્યા</b> के अलावा सभी शब्दों में <b>દ્ર</b> का उपयोग हुआ है।',
      en: 'All words have <b>દ્ર</b> in them, except <b>વિદ્યા</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['નૈવેદ્ય', 'બાહ્ય', 'ગુહ્ય', 'અસહ્ય'],
    answer: ['નૈવેદ્ય'],
    reason: {
      gu: '<b>નૈવેદ્ય</b> સિવાયના બધા શબ્દોમાં <b>હ્ય</b>નો પ્રયોગ થયો છે.',
      hi: '<b>નૈવેદ્ય</b> के अलावा सभी शब्दों में <b>હ્ય</b> का उपयोग हुआ है।',
      en: 'All words have <b>હ્ય</b> in them, except <b>નૈવેદ્ય</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['બ્રહ્માંડ', 'વિદ્યાભ્યાસ', 'વિદ્યાર્થી', 'ઉદ્યોગ'],
    answer: ['બ્રહ્માંડ'],
    reason: {
      gu: '<b>બ્રહ્માંડ</b> સિવાયના બધા શબ્દોમાં <b>દ્ય</b>નો પ્રયોગ થયો છે.',
      hi: '<b>બ્રહ્માંડ</b> के अलावा सभी शब्दों में <b>દ્ય</b> का उपयोग हुआ है।',
      en: 'All words have <b>દ્ય</b> in them, except <b>બ્રહ્માંડ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['ઈશ્વર', 'પાર્શ્વનાથ', 'શ્રી રામ', 'વિશ્વ'],
    answer: ['શ્રી રામ'],
    reason: {
      gu: '<b>શ્રી રામ</b> સિવાયના બધા શબ્દોમાં <b>શ્વ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>શ્રી રામ</b> के अलावा सभी शब्दों में <b>શ્વ</b> का उपयोग हुआ है।',
      en: 'All words have <b>શ્વ</b> in them, except <b>શ્રી રામ</b>.'
    }
  },
  {
    level: 1,
    question: 'Find the odd one out.',
    options: ['નિશ્ચય', 'પશ્ચિમ', 'નિશ્ચિત', 'શ્વાન'],
    answer: ['શ્વાન'],
    reason: {
      gu: '<b>શ્વાન</b> સિવાયના બધા શબ્દોમાં <b>શ્ચ</b>નો પ્રયોગ થયો છે.',
      hi: '<b>શ્વાન</b> के अलावा सभी शब्दों में <b>શ્ચ</b> का उपयोग हुआ है।',
      en: 'All words have <b>શ્ચ</b> in them, except <b>શ્વાન</b>.'
    }
  }
]
